<mat-icon class="notification-container" *ngIf="notifications.length > 0" xpo-badge-important-notification
  [matBadge]="notifications.length" [xpoLtlpopoverTriggerFor]="notificationPopover">notifications
</mat-icon>

<mat-icon *ngIf="notifications.length === 0" xpo-badge-important-notification
  [xpoLtlpopoverTriggerFor]="notificationPopover">notifications
</mat-icon>

<xpo-ltl-popover #notificationPopover position="below" arrowBehaviourAuto="true">
  <xpo-ltl-popover-header>
    <div class="popover-header">Notifications</div>
  </xpo-ltl-popover-header>
  <xpo-ltl-popover-content>
    <div *ngIf="notifications.length > 0">
      <table mat-table [dataSource]="notifications">
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef>Incentive ID</th>
          <td mat-cell *matCellDef="let notification">
            <div [class.error]="notification.canRetry">
              <strong>{{ notification.title }}</strong>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="customer">
          <th mat-header-cell *matHeaderCellDef>Customer</th>
          <td mat-cell *matCellDef="let notification">
            <div>
              {{ notification.emphasis }} {{ notification.description ? notification.divider : '' }}
              {{ notification.description }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let notification">
            <div *ngIf="notification.canRetry">{{ notification.runStatusCd }}</div>
            <div *ngIf="!notification.canRetry">Processing</div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="notificationColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: notificationColumns"></tr>
      </table>
    </div>
    <div *ngIf="notifications.length === 0" class="no-notification-container">No notifications yet</div>
  </xpo-ltl-popover-content>
</xpo-ltl-popover>
